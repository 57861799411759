import axios from "axios";
// import Vue from "vue";
import Toast from "@/components/Toast/ToastIndex";
import store from "@/store";
import router from "@/router";
import { LocalDataUtil } from "./util";
// Vue.use(Toast);
// const domain = "https://api.aispace.ink";
// const domain = "http://10.61.160.79:8888";
// const domain = "https://api-qa.aiemoji.net/";
const domain = "https://api-qa.aispark.ai";
// const domain = "https://api.aispace.ink";
axios.defaults.baseURL = domain;
axios.interceptors.request.use(
  (config) => {
    console.info(config);
    const token = LocalDataUtil.getItem("aispacewebtoken");
    const Language = navigator.language || navigator.userLanguage;
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    if (Language) {
      config.headers.Language = Language;
    }
    return config;
  },
  (error) => {
    console.info(error);
  }
);
axios.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      if (res.data.status !== "200") {
        if (res.data.code === "40001" || res.data.code === "49113") {
          console.log("token失效");
          LocalDataUtil.clearItem();
          router.push({ name: "home" });
          store.commit("setShowLogin", true);
          store.commit("setCommunityModalisible", false);
        } else {
          const message =
            res.data.details[0] || res.data.msg || "Service error";
          Toast({
            type: "error",
            message,
          });
        }
      }
      return res.data;
    } else {
      Toast({
        type: "error",
        message: res.msg || "Service error",
      });
      return {
        status: res.status,
        msg: "服务异常，请联系管理员",
      };
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
